.page-navbar {
    &.navbar-dark {
        background-color: transparent !important;
        padding: 0;
        z-index: 98;

        @include media-breakpoint-down(md) {
            background: map-get($theme-colors, "dark") !important;
        }
    }

    .navbar-brand {
        background: transparent;
        border: 0;
        box-shadow: none;
    }

    .nav-item {
        .nav-link {
            text-transform: uppercase;
            display: inline-block;
            color: white;
            transition: box-shadow .5s cubic-bezier(0.76, 0, 0.24, 1);
            box-shadow: inset 0 0 0 0 map-get($theme-colors, "primary"),
                        inset 0 0 0 0 map-get($theme-colors, "primary");
            padding-left: 30px;
            width: 100%;

            @include media-breakpoint-up(lg) {
                min-height: 80px;
                line-height: 80px;
                padding-left: 0;
                width: auto;
            }

            &:hover,
            &:active,
            &:focus {
                box-shadow: inset 0 4px 0 0 map-get($theme-colors, "primary"),
                            inset 0 -4px 0 0 map-get($theme-colors, "primary");
            }

            &-diamond {
                display: inline-block;
                margin-left: 10px;
                margin-right: 20px;
                transform: translateY(-2px);

                @include media-breakpoint-down(md) {
                    display: none;
                }
            }
        }

        &.dropdown {
            @include media-breakpoint-up(lg) {
                position: static;
            }
        }

        .dropdown {
            &-toggle {
                &::after {
                    display: none;
                }
            }

            &-menu {
                border-radius: 0;
                border: 0;
                border-top: 4px solid map-get($theme-colors, "primary");
                box-shadow: none;
                background: map-get($theme-colors, "dark");

                @include media-breakpoint-up(lg) {
                    width: 100%;
                    top: auto;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    transform: translate3d(0, 100%, 0);
                    justify-content: center;

                    &.show {
                        display: flex;
                    }
                }

                .dropdown-item {
                    padding: 15px;
                    white-space: normal;
                    display: flex;
                    align-items: center;
                    font-family: 'Playfair Display', serif;
                    font-size: 20px;
                    line-height: 1.4;
                    transition: all .3s ease-in-out;

                    &:hover,
                    &:active,
                    &:focus {
                        background: transparent;
                        border: 0;
                        text-decoration: none;

                        > img {
                            transform: rotate(-10deg);
                        }
                    }

                    @include media-breakpoint-up(lg) {
                        max-width: 280px;
                    }

                    > img {
                        margin-right: 18px;
                        transform: rotate(0deg);
                        transition: transform .5s cubic-bezier(0.76, 0, 0.24, 1);
                    }
                }
            }
        }
    }

    .social-link-wrapper {
        @include media-breakpoint-up(lg) {
            min-width: 167px;
        }
    }

    &-fixed {
        transition: transform 1s cubic-bezier(0.76, 0, 0.24, 1);
        transform: translateY(0);
        max-height: 100vh;
        z-index: 99;

        @include media-breakpoint-down(md) {
            overflow: auto;
        }

        &.is-hidden {
            transform: translateY(-100%);
        }

        &.navbar-dark {
            background-color: map-get($theme-colors, "dark") !important;
        }

        .nav-item {
            .nav-link {
                min-height: 40px;
                line-height: 40px;
            }
        }

        .social-link-wrapper {
            @include media-breakpoint-up(lg) {
                min-width: 140px;
            }
        }
    }
}

.nav {
    .nav-item {
        .nav-link {
            font-family: 'Playfair Display', sans-serif;
            color: white;
            text-decoration: none;
            font-size: 18px;
            line-height: 1.2;
            letter-spacing: .4px;
            text-transform: uppercase;

            &:hover,
            &:active,
            &:focus {
                opacity: .8;
            }
        }
    }
}