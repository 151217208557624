.section-header {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: map-get($theme-colors, "dark");
    margin-top: -$navbar-height;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        background: rgba(black, .5);
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &-title {
        padding-top: $navbar-height;
        padding-bottom: 30px;

        @include media-breakpoint-up(lg) {
            min-height: calc(260px + #{$navbar-height});
        }

        &-small {
            @include media-breakpoint-up(lg) {
                min-height: calc(200px + #{$navbar-height});
            }
        }
    }
}