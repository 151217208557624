$navbar-height: 96px;

.homepage-carousel {
    margin-top: -$navbar-height; 

    .carousel-inner {
        .carousel-item {
            background-size: cover;
            background-position: center center;
            padding-top: $navbar-height;

            &.video-bg {
                position: relative;

                > video {
                    object-fit: cover;
                    object-position: center center;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 2;
                }

                > .container {
                    position: relative;
                    z-index: 2;
                }
            }

            h1 {
                font-size: 48px;
                color: rgba(white, .5);
                line-height: 1;
                font-weight: 800;
                
                @include media-breakpoint-up(lg) {
                    font-size: 85px;
                }
            }

            a {
                margin-bottom: 30px;
            }

            .carousel-item-content {
                min-height: 75vh;
                display: flex;
                flex-direction: column;

                @include media-breakpoint-up(lg) {
                    min-height: calc(60vh + #{$navbar-height}); 
                }
            }

            .carousel-item-content {
                justify-content: center;
                text-align: center;
                @extend .col-12;
                @extend .col-lg-8;
                @extend .offset-lg-2;

                a {
                    align-self: center;
                }
            }
        }
    }

    .carousel-indicators {
        transform: rotate(90deg);
        top: 50%;
        right: 0;
        bottom: auto;
        left: auto;
        margin: 0;

        @include media-breakpoint-down(lg) {
            display: none;
        }

        li {
            width: 10px;
            height: 10px;
            border: 2px solid white;
            border-radius: 50%;
            background: transparent;
            opacity: 1;
            margin-left: 5px;
            margin-right: 5px;

            &.active {
                background: white;
            }
        }
    }

    .slider-ornament {
        position: absolute;
        top: auto;
        right: auto;
        bottom: 0;
        left: 50%;
        transform: translate3d(-50%, -30px, 0);
    }
}