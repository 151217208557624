.primary-cta {
    font-size: 16px;
    line-height: 1.2;
    padding: 10px 10px 5px;
    border: 2px solid white;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    transition: all .3s ease-in-out;

    &:hover,
    &:active,
    &:focus {
        background: white;
        color: map-get($theme-colors, "dark");
        text-decoration: none;
    }

    @include media-breakpoint-up(lg) {
        font-size: 26px;
        padding: 15px 15px 10px;
    }

    &-alt {
        border: 2px solid map-get($theme-colors, "primary");
        color: map-get($theme-colors, "primary");

        &:hover,
        &:active,
        &:focus {
            background: map-get($theme-colors, "primary");
            color: white;
            text-decoration: none;
        }

        &.background-transparent {
            background: transparent;
    
            &:hover,
            &:active,
            &:focus {
                background: map-get($theme-colors, "primary");
            }
        }
    }
}

.secondary-cta {
    font-size: 16px;
    line-height: 1.2;
    padding: 15px 25px 10px;
    border: 2px solid white;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    transition: all .3s ease-in-out;

    &:hover,
    &:active,
    &:focus {
        background: white;
        color: map-get($theme-colors, "dark");
        text-decoration: none;
    }
}

.button-unstyled {
    padding: 0;
    margin: 0;
    border: 0;
    background: transparent;
    border-radius: 0;
}

.link-text {
    text-transform: uppercase;
    font-size: 15px;
    color: map-get($theme-colors, "primary");
}