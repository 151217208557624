.product-slider {
    background: white;

    &-component {
        .carousel-item {
            > img {
                height: 320px;
                object-fit: cover;
                object-position: center center;

                @include media-breakpoint-up(lg) {
                    height: 500px;
                }
            }
        }

        .carousel-control-next,
        .carousel-control-prev {
            @include media-breakpoint-down(lg) {
                display: none;
            }

            &-icon {
                width: 27px;
                height: 15px;
                transform: translateY(-40px);
            }
        }

        .carousel-control-next {
            @include media-breakpoint-up(lg) {
                right: -120px;
            }

            &-icon {
                background-image: url(../img/icon-arrow-black-right.svg);
            }
        }

        .carousel-control-prev {
            @include media-breakpoint-up(lg) {
                left: -120px;
            }

            &-icon {
                background-image: url(../img/icon-arrow-black-left.svg);
            }
        }

        .carousel-indicators {
            position: static;
            right: auto;
            bottom: auto;
            left: auto;
            z-index: 15;
            display: flex;
            justify-content: center;
            padding-left: 0;
            margin-right: auto;
            margin-left: auto;
            list-style: none;
            border-top: 2px solid map-get($theme-colors, "primary");

            li {
                box-sizing: border-box;
                flex: 0 1 auto;
                width: 78px;
                height: auto;
                margin-right: 5px;
                margin-left: 5px;
                margin-bottom: 5px;
                text-indent: 0;
                border: 2px solid map-get($theme-colors, "primary");

                @include media-breakpoint-up(lg) {
                    width: 140px;
                }
            }

            .img-fluid {
                width: 78px;
                height: 78px;
                object-fit: cover;
                object-position: center center;

                @include media-breakpoint-up(lg) {
                    width: 140px;
                }
            }
        }
    }
}