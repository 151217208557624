.product-detail {
    background: white;

    p {
        font-size: 16px;
    }

    .bulleted-list {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            font-size: 16px;
            color: map-get($theme-colors, "dark");

            &::before {
                content: '» ';
                color: map-get($theme-colors, "primary")
            }
        }
    }

    &-characteristics {
        @include media-breakpoint-up(lg) {
            border-right: 2px solid map-get($theme-colors, "primary");
        }
    }
}