.footer {
    background: map-get($theme-colors, "dark");

    .border-top-dark-alt {
        border-top: 2px solid map-get($theme-colors, "dark-alt");
    }

    .border-bottom-dark-alt {
        border-bottom: 2px solid map-get($theme-colors, "dark-alt");
    }

    .border-right-dark-alt {
        border-right: 2px solid map-get($theme-colors, "dark-alt");

        @include media-breakpoint-down(lg) {
            border-right: 0;
        }
    }

    .subfooter {
        img {
            vertical-align: middle;
            line-height: 1.4;
            display: inline-block;
            margin-top: -7px;
            margin-left: 4px;
        }
    }

    p {
        font-size: 14px;
        color: white;
        font-weight: 300;
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        
        li {
            color: white;
            font-weight: 300;

            a {
                text-decoration: none;
            }
        }

        &.w-bullets {
            li {
                &::before {
                    content: '» ';
                    color: map-get($theme-colors, "primary")
                }

                &.no-bullet {
                    &::before {
                        content: '';
                    }
                }
            }
        }
    }
}