.component-newsletter {
    background: transparent url(../img/newsletter-bg.jpg) no-repeat center center / cover;

    p {
        font-size: 16px;
        line-height: 1.25;
        font-weight: 400;
        color: black;
    }

    input[type="email"] {
        display: block;
        max-width: 320px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 15px;
    }
}

.component-newsletter-form {
    background: map-get($theme-colors, "light");

    .icon-newsletter {
        transform: translateY(-6px);
    }

    input[type="email"] {
        max-width: 320px;
        vertical-align: middle;
        transform: translateY(-4px);
    }

    button {
        transform: translateY(-6px);
    }
}