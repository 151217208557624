input[type="email"],
input[type="text"],
textarea {
    font-size: 16px;
    line-height: 1.2;
    padding: 10px 10px 5px;
    border: 2px solid map-get($theme-colors, "primary");
    color: map-get($theme-colors, "primary");
    background: white;
    text-decoration: none;
    transition: all .3s ease-in-out;
    width: 100%;

    &::placeholder {
        opacity: .75;
        color: map-get($theme-colors, "primary");
    }

    &:hover,
    &:active,
    &:focus {
        border: 2px solid map-get($theme-colors, "primary");
        color: map-get($theme-colors, "primary");
        background: white;
        text-decoration: none;
        border-radius: 0;
    }

    @include media-breakpoint-up(lg) {
        font-size: 26px;
        padding: 15px 15px 10px;
    }

    &.input-bottom-border {
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 1px solid map-get($theme-colors, "primary");
        background: transparent;

        @include media-breakpoint-up(lg) {
            font-size: 16px;
            padding: 10px 10px 5px;
        }
    }
}

textarea {
    min-height: 150px;
}