@import "variables.scss";
@import "colors.scss";
@import "node_modules/bootstrap/scss/bootstrap";
@import "fonts.scss";
@import "icons.scss";
@import "mixins.scss";
@import "utilities.scss";
@import "animation.scss";
@import "inputs.scss";
@import "buttons.scss";
@import "headings.scss";
@import "navbar.scss";
@import "homepage/slider.scss";
@import "homepage/about.scss";
@import "homepage/creaciones.scss";
@import "components/newsletter.scss";
@import "components/testimonials.scss";
@import "components/section-header.scss";
@import "components/about.scss";
@import "about/description.scss";
@import "about/tagline.scss";
@import "contact/form.scss";
@import "contact/showroom-location.scss";
@import "contact/map.scss";
@import "product/slider.scss";
@import "product/detail.scss";
@import "product/listing.scss";
@import "footer.scss";
@import "form.scss";
