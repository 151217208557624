.contact-form {
    background: white;

    .paragraph-dark {
        font-weight: 300;
        font-size: 18px;
        line-height: 1.4;

        @include media-breakpoint-up(lg) {
            font-size: 22px;
        }
    }
}