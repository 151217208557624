.homepage-creaciones {
    padding-top: 30px;
    padding-bottom: 30px;
    background: white;

    h1 {
        color: black;
        font-size: 24px;
        line-height: 1.4;
        margin: 0 0 30px;
        padding: 0 0 30px;
        position: relative;
        z-index: 2;

        @include media-breakpoint-up(lg) {
            font-size: 45px;
        }

        &::before,
        &::after {
            width: 20px;
            height: 7px;
            display: inline-block;
            vertical-align: middle;
            margin: 0 5px;
            transform: translateY(-4px);

            @include media-breakpoint-up(lg) {
                margin: 0 15px;
                width: 46px;
                height: 18px;
            }
        }

        &::before {
            content: url(../img/ornament-title-left.svg);
        }

        &::after {
            content: url(../img/ornament-title-right.svg);
        }
    }

    .creaciones-slider {
        &-link {
            position: relative;
            text-decoration: none;

            > * {
                text-decoration: none;
            }

            &:hover,
            &:active,
            &:focus {
                text-decoration: none;

                .hover-content {
                    opacity: 1;
                    transform: scale(1);
                }
            }

            > .img-fluid {
                @include media-breakpoint-up(lg) {
                    height: 228px;
                    object-fit: contain;
                    object-position: center center;
                }
            }

            .icon-more {
                display: inline-block;
                margin-bottom: 20px;
            }

            .hover-content {
                background: rgba(black, .5);
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                transform: scale(.5);
                transition: all .75s cubic-bezier(0.76, 0, 0.24, 1);

                .secondary-cta {
                    position: absolute;
                    top: 50%;
                    right: auto;
                    bottom: auto;
                    left: 50%;
                    transform: translate3d(-50%, -50%, 0);
                }
            }
        }

        .carousel-control-next,
        .carousel-control-prev {
            opacity: 1;
            width: 28px;
            top: auto;
            bottom: 0;
            z-index: 3;

            &-icon {
                width: 27px;
                height: 15px;
            }
        }

        .carousel-control-next-icon {
            background-image: url(../img/icon-arrow-black-right.svg);
        }

        .carousel-control-prev-icon {
            background-image: url(../img/icon-arrow-black-left.svg);
        }

        .carousel-indicators {
            margin: 0;
            z-index: 2;
    
            li {
                width: 10px;
                height: 10px;
                border: 1px solid black;
                border-radius: 50%;
                background: transparent;
                opacity: 1;
                margin-left: 5px;
                margin-right: 5px;
    
                &.active {
                    background: black;
                }
            }
        }
    }
}