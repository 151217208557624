.about-description {
    overflow: hidden;
    background: white;

    .paragraph-dark {
        font-weight: 500;
        border-top: 2px solid map-get($theme-colors, "primary");
        padding-top: 30px;
        margin-top: 30px;
        margin-bottom: 0;

        @include media-breakpoint-up(lg) {
            font-size: 20px;
        }
    }

    .about-description-main-image {
        @include media-breakpoint-up(lg) {
            max-width: calc(100% + 200px);
        }
    }
}