.homepage-about {
    padding-top: 60px;
    padding-bottom: 60px;
    background: map-get($theme-colors, "dark") url(../img/about-block-bg.jpg) no-repeat center center / cover;

    h1 {
        color: white;
        font-size: 36px;
        line-height: 1.4;
        margin: 0 0 30px;
        padding: 0 0 30px;
        position: relative;
        z-index: 2;

        @include media-breakpoint-up(lg) {
            font-size: 45px;
            width: calc(100% + 180px);
        }
    }
}