.pretitle {
    color: map-get($theme-colors, "primary");
    font-family: 'Josefin Sans', sans-serif !important;
    font-size: 18px;
    line-height: 1.2;
    font-weight: 300;
    margin: 0 0 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.label {
    color: map-get($theme-colors, "primary");
    font-family: 'Josefin Sans', sans-serif !important;
    font-size: 14px;
    line-height: 1.2;
    font-weight: 300;
    margin: 0 0 20px;
    text-transform: uppercase;
    letter-spacing: 0;
    text-decoration: none;

    &-alt {
        color: white;
        font-weight: 500;
    }
}

.subtitle {
    color: map-get($theme-colors, "dark");
    font-size: 24px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0;
}

.title {
    &-golden {
        font-weight: 800;
        font-size: 28px;
        line-height: 1;
        color: map-get($theme-colors, "primary");
        margin: 10px 0 15px;

        @include media-breakpoint-up(lg) {
            font-size: 34px;
        }
    }

    &-copper {
        @extend .title-golden;
        color: map-get($theme-colors, "copper");
    }

    &-white {
        font-weight: 800;
        font-size: 28px;
        line-height: 1;
        color: white;
        margin: 10px 0 15px;

        @include media-breakpoint-up(lg) {
            font-size: 34px;
        }

        &.font-size-small {
            @include media-breakpoint-up(lg) {
                font-size: 30px;
            }
        }

        &.font-size-small-mobile {
            @include media-breakpoint-down(lg) {
                font-size: 22px;
            }
        }

        &.font-size-large {
            font-size: 28px;

            @include media-breakpoint-up(lg) {
                font-size: 42px;
            }
        }

        &.font-size-jumbotron {
            font-size: 28px;

            @include media-breakpoint-up(lg) {
                font-size: 64px;
            }
        }
    }
    
    &-dark {
        font-weight: 800;
        font-size: 28px;
        line-height: 1;
        color: map-get($theme-colors, "dark");
        margin: 10px 0 15px;

        @include media-breakpoint-up(lg) {
            font-size: 34px;
        }

        &.font-size-medium {
            font-size: 28px;

            @include media-breakpoint-up(lg) {
                font-size: 38px;
            }
        }

        &.font-size-large {
            font-size: 28px;

            @include media-breakpoint-up(lg) {
                font-size: 42px;
            }
        }
    }

    &-subsection {
        color: map-get($theme-colors, "copper");
        font-size: 26px;
        line-height: 1.4;
        letter-spacing: .25px;
        margin: 0 10px;
    }
}