.homepage-testimonials {
    padding-top: 60px;
    padding-bottom: 60px;
    background: black url(../img/testimonial-bg.jpg) no-repeat center center / cover;

    h1 {
        color: black;
        font-size: 24px;
        line-height: 1.4;
        margin: 0 0 30px;
        padding: 0 0 30px;
        position: relative;
        z-index: 2;

        @include media-breakpoint-up(lg) {
            font-size: 45px;
        }

        &::before,
        &::after {
            width: 20px;
            height: 7px;
            display: inline-block;
            vertical-align: middle;
            margin: 0 5px;
            transform: translateY(-4px);

            @include media-breakpoint-up(lg) {
                margin: 0 15px;
                width: 46px;
                height: 18px;
            }
        }

        &::before {
            content: url(../img/ornament-title-left.svg);
        }

        &::after {
            content: url(../img/ornament-title-right.svg);
        }
    }

    .testimonials-slider {
        .title-white {
            line-height: 1.4;
            max-width: 1024px;
            margin: 0 auto 40px;
            position: relative;

            @include media-breakpoint-up(lg) {
                margin: 0 auto 100px;
            }

            &::before {
                position: absolute;
                z-index: 2;
                top: -30px;
                left: 0;
                content: url(../img/icon-quote.svg);
                width: 60px;
                height: auto;

                @include media-breakpoint-up(lg) {
                    width: 100px;
                    left: -30px;
                }
            }
        }

        .carousel-control-next,
        .carousel-control-prev {
            opacity: 1;
            width: 28px;
            top: auto;
            bottom: 0;
            z-index: 3;

            &-icon {
                width: 27px;
                height: 15px;
            }
        }

        .carousel-control-next-icon {
            background-image: url(../img/icon-arrow-white-right.svg);
        }

        .carousel-control-prev-icon {
            background-image: url(../img/icon-arrow-white-left.svg);
        }

        .carousel-indicators {
            margin: 0;
            z-index: 2;
    
            li {
                width: 10px;
                height: 10px;
                border: 1px solid white;
                border-radius: 50%;
                background: transparent;
                opacity: 1;
                margin-left: 5px;
                margin-right: 5px;
    
                &.active {
                    background: white;
                }
            }
        }
    }
}