$theme-colors: (
    "primary": #9a8468,
    "dark": #111110,
    "dark-alt": #30302f,
    "copper": #25190d,
    "light": #f3f3f3
);

.bg-scarlet{
    background-color: $scarlet;
}