@import "imports.scss";

body {
  font-family: $font-family;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
  color: #3b3b53;
  background-color: $body-color;
  height: 100%;
}

html {
  position: relative;
  min-height: 100%;
}

a {
  &:focus {
    outline: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 600;
  line-height: 1.1em;
  margin-bottom: 20px;
}

.content-wrapper {
  background-color: $body-color;
  width: 100%;
  overflow-x: hidden;
}

.scroll-container{
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
}

.flex-basis-auto {
  -webkit-flex-basis: auto;
  -moz-flex-basis: auto;
  -ms-flex-basis: auto;
  flex-basis: auto;
}

.btn-disabled {
  color: $white;
  opacity: 0.5;
  text-decoration: none;
  pointer-events: none;
  background-color: $gray-500;
  border-color: $gray-500;
}

.btn-primary {
  min-width: 130px;
  height: 35px;
  padding: 7px 15px;
  border-radius: 4px;
  background-color: $primary;
  color: $white;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.38px;
  text-align: center;
  text-decoration: none;
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-center;
  &:active,
  &:hover,
  &:focus {
    background-position: 100% 0;
    transition: all 0.3s ease-in-out;
    color: $white;
    outline: none;
    text-decoration: none;
    @include box-shadow(1px 1px rgba($primary, 0.4));
  }
}

.btn-secondary,
.btn-secondary.dropdown-toggle {
  min-width: 130px;
  height: 35px;
  padding: 7px 15px;
  border-radius: 4px;
  border-color: $primary;
  background: none;
  color: $gray-800;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.38px;
  text-align: center;
  text-decoration: none;
  box-shadow: none;
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-center;
  &:active,
  &:hover,
  &:focus {
    background-position: 100% 0;
    transition: all 0.3s ease-in-out;
    color: $white;
    outline: none;
    text-decoration: none;
    background: $primary !important;
    border-color: $primary;
    @include box-shadow(1px 1px rgba($primary, 0.4));
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):focus {
    border-color: $primary;
    @include box-shadow(1px 1px rgba($primary, 0.4));
  }
}

.bg-dark {
  background-color: #5c2a9d !important;
}

.hide {
  display: none !important;
}

/* Navbar */

 .navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, .25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.navbar .navbar-toggler {
  top: .25rem;
  right: 1rem;
}

.navbar .form-control {
  padding: .75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

